
import { Component, Vue, Watch } from "vue-property-decorator";
import api from "@/api";
import { TaskInterface } from "@/interfaces/task";
import { VehicleInterface } from "@/interfaces/vehicle";
import Vehicle = VehicleInterface.Vehicle;
import Task = TaskInterface.Task;
import FilterEcu from "@/components/_inner/FilterEcu.vue";
import ThePromo from "@/components/price/ThePromo.vue";
import Patches from "@/components/_inner/Patches.vue";
import Dtc from "@/components/_inner/Dtc.vue";
import ResultPanel from "@/components/_inner/ResultPanel.vue";
import NoteWarning from "@/components/_tools/NoteWarning.vue";
import NoteError from "@/components/_tools/NoteError.vue";
import storage from "@/storage";
import getCurrencySymbol from "@/additionally/getCurrencySymbol";
import getLangIdObject from "@/additionally/getLangIdObject";
import ModalMmcError from "@/components/_modal/ModalMmcError.vue";
import ModalProcess from "@/components/_modal/ModalProcess.vue";

type Payback = {
  done: Function;
};

@Component({
  components: {
    ModalProcess,
    ModalMmcError,
    FilterEcu,
    ThePromo,
    NoteError,
    NoteWarning,
    Dtc,
    Patches,
    ResultPanel,
  },
  metaInfo() {
    return {
      title: this.$tc("caption"),
    };
  },
})
export default class PricesPage extends Vue {
  startFilterParams = {};
  task: Task | null = null;
  promoCode = "";
  error = false;
  currencyId = 1;
  dtcBillingDescription = "";
  dtcBillingNote = "";
  promoDescription = "";
  isShowProcessPayment = false;
  isShowModalError = false;

  @Watch("$i18n.locale")
  onWatchLocales() {
    this.getBillingInfo();
  }

  async saveTask(
    payback: Payback = {
      done: () => {
        console.log(
          "typescript + vue2 = вот такие вот костыли приходиться писать, лень разбираться как это чинить"
        );
      },
    }
  ): Promise<boolean> {
    if (!this.task) return false;
    const task = await api.task.save(this.task);
    this.refreshTask(task);

    this.$nextTick(() => {
      payback.done();
    });
    return !!task;
  }

  getStartFilterParams() {
    const params = ["type", "brand", "model", "engine", "ecu"];
    const ids = {};
    params.forEach((item: string) => {
      if (this.$route.query[item]) ids[`${item}Id`] = +this.$route.query[item];
    });
    this.startFilterParams = ids;
  }

  getBillingInfo() {
    if (this.task) {
      const billingDescriptions: TaskInterface.BillingDescriptions = getLangIdObject<
        TaskInterface.BillingDescriptions
      >(this.task.billingDescriptions);

      this.dtcBillingDescription = billingDescriptions.tdcBillingDescription;
      this.dtcBillingNote = billingDescriptions.tdcBillingNote;
      this.promoDescription = billingDescriptions.promoDescription;
    }
  }

  getCurrencyId() {
    const id = storage.user?.currencyId;
    if (id) this.currencyId = id;
  }

  async createTask(data: { completed: boolean; vehicle: Vehicle }) {
    if (!data.completed) return (this.task = null);
    const task = await api.task.preview(data.vehicle);
    this.refreshTask(task);
    storage.lastVehicle = data.vehicle;
  }

  async calcPrice() {
    if (!this.task) return;
    const task = await api.task.calcPriceV2({
      ...this.task,
      promoCode: this.promoCode,
      currencyId: this.currencyId,
    });
    this.refreshTask(task);
  }

  refreshTask(task: TaskInterface.Task | null) {
    this.error = false;
    if (!task) return (this.error = true);
    this.task = task;
    storage.lastTaskVehicle = task ? task.vehicle : null;
    this.getBillingInfo();
  }

  refreshPromoCode(code: string) {
    this.promoCode = code;
    this.calcPrice();
  }

  showProcessPayment () {
    this.isShowProcessPayment = true
  }

  closeProcessPayment () {
    this.isShowProcessPayment = false
  }

  showModalError() {
    this.isShowModalError = true
  }

  closeModalError() {
    this.isShowModalError = false
  }

  eventError () {
    this.showModalError()
    this.closeProcessPayment()
  }

  get price(): number {
    let sum = 0;
    if (!this.task) return sum;
    if (this.task.patchesTotalPriceSum) sum += this.task.patchesTotalPriceSum;
    if (this.task.taskDtcCurrentTotalPriceSum)
      sum += this.task.taskDtcCurrentTotalPriceSum;
    return sum;
  }

  get discount(): number {
    let sum = 0;
    if (!this.task) return sum;
    if (this.task.patchesTotalDiscountSum)
      sum += this.task.patchesTotalDiscountSum;
    if (this.task.taskDtcCurrentTotalDiscountSum)
      sum += this.task.taskDtcCurrentTotalDiscountSum;
    return sum;
  }

  get currency(): string {
    if (!this.task) return "";
    return getCurrencySymbol(this.task.currencyId);
  }

  created() {
    this.getStartFilterParams();
    this.getBillingInfo();
  }

  mounted() {
    this.getCurrencyId();
  }

  getDescriptionData() {
    return this.task?.subscription;
  }
}
