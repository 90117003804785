
import '@/assets/styles/imports/filter-ecu.sass'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { VehicleInterface } from '@/interfaces/vehicle'
import api from '@/api'
import Vehicle = VehicleInterface.Vehicle;

@Component
export default class FilterEcu extends Vue {
  @Prop({ type: Number, default: 0 }) startTypeId!: number
  @Prop({ type: Number, default: 0 }) startBrandId!: number
  @Prop({ type: Number, default: 0 }) startModelId!: number
  @Prop({ type: Number, default: 0 }) startEngineId!: number
  @Prop({ type: Number, default: 0 }) startEcuId!: number
  @Prop({ type: Boolean, default: false }) startReadOnly!: boolean

  params = ['type', 'brand', 'model', 'engine', 'ecu']
  types: Array<VehicleInterface.Type> = []
  typeId = this.startTypeId
  brands: Array<VehicleInterface.Brand> = []
  brandId = this.startBrandId
  models: Array<VehicleInterface.Model> = []
  modelId = this.startModelId
  engines: Array<VehicleInterface.Engine> = []
  engineId = this.startEngineId
  ecus: Array<VehicleInterface.Ecu> = []
  ecuId = this.startEcuId
  readonly = this.startReadOnly

  @Watch('startReadOnly')
  onStartReadOnlyChanged(val: boolean) {
    this.readonly = val
  }

  @Watch('startTypeId')
  onStartTypeIdChanged (val: number) {
    this.typeId = val
  }

  @Watch('startBrandId')
  onStartBrandIdChanged (val: number) {
    this.brandId = val
    this.getBrands()
  }

  @Watch('startModelId')
  onStartModelIdChanged (val: number) {
    this.modelId = val
    this.getModels()
  }

  @Watch('startEngineId')
  onStartEngineIdChanged (val: number) {
    this.engineId = val
    this.getEngines()
  }

  @Watch('startEcuId')
  onStartEcuIdChanged (val: number) {
    this.ecuId = val
    this.getEcus()
    this.checkCompletedField()
  }

  getTypes () {
    api.vehicle.getVehicleType()
      .then(types => {
        this.types = types
        if (types.length !== 1) return
        this.typeId = types[0].id
        this.getBrands()
      })
  }

  getBrands () {
    api.vehicle.getVehicleBrand({ typeId: this.typeId })
      .then(brands => {
        this.brands = brands
        if (brands.length !== 1) return
        this.brandId = brands[0].id
        this.getModels()
    })
  }

  getModels () {
    api.vehicle.getVehicleModel({ typeId: this.typeId, brandId: this.brandId })
    .then(models => {
      this.models = models
      if (models.length !== 1) return
      this.modelId = models[0].id
      this.getEngines()
    })
  }

  getEngines () {
    api.vehicle.getVehicleEngine({
      typeId: this.typeId,
      brandId: this.brandId,
      modelId: this.modelId
    }).then(engines => {
      this.engines = engines
      if (engines.length !== 1) return
      this.engineId = engines[0].id
      this.getEcus()
    })
  }

  getEcus () {
      api.vehicle.getVehicleEcu({
      typeId: this.typeId,
      brandId: this.brandId,
      modelId: this.modelId,
      engineId: this.engineId
    }).then(ecus => {
      this.ecus = ecus
      if (ecus.length === 1) this.ecuId = ecus[0].id
      this.checkCompletedField()
    })
  }

  getEnginesA() {
    api.vehicle.getVehicleEngine({
      typeId: this.typeId,
      brandId: this.brandId,
      modelId: this.modelId
    }).then(engines => {
      this.engines = engines
      if (engines.length !== 1) return
      this.engineId = engines[0].id
      this.getEcusA()
    })
  }

  getEcusA() {
    api.vehicle.getVehicleEcu({
      typeId: this.typeId,
      brandId: this.brandId,
      modelId: this.modelId,
      engineId: this.engineId
    }).then(ecus => {
      this.ecus = ecus
      if (ecus.length === 1) this.ecuId = ecus[0].id
    })
  }

  changeSelect (field: string) {
    this.cleanNextField(field)
    if (field === 'type') this.getBrands()
    else if (field === 'brand') this.getModels()
    else if (field === 'model') this.getEngines()
    else if (field === 'engine') this.getEcus()
    this.checkCompletedField()
  }

  cleanNextField (field: string): void {
    let clean = false
    this.params.forEach((item: string) => {
      if (clean) this[`${item}Id`] = 0
      if (field === item) clean = true
    })
  }

  getStartLists () {
    this.getTypes()
    if (!this.typeId) return
    this.getBrands()
    this.getModels()
    this.getEngines()
    this.getEcus()
  }

  setAnotherEcu(vehicle: Vehicle) {
    //console.log('setAnotherEcu')
    this.typeId = vehicle.type.id,
    this.brandId = vehicle.brand.id,
    this.modelId= vehicle.model.id,
    this.engineId= vehicle.engine.id,
    this.ecuId= vehicle.ecu.id,
    this.getEnginesA()
    this.getEcusA()
  }

  checkCompletedField() {
    //console.log('checkCompletedField')
    for (let i = 0, len = this.params.length; i < len; i++) {
      const params = this.params[i]
      if (this[`${params}Id`] < 1) {
        this.$emit('refresh-filter', { completed: false, vehicle: this.getParams() })
        return
      }
    }
    this.$emit('refresh-filter', { completed: true, vehicle: this.getParams() })
  }

  getParams () {
    return {
      type: { id: this.typeId },
      brand: { id: this.brandId },
      model: { id: this.modelId },
      engine: { id: this.engineId },
      ecu: { id: this.ecuId }
    }
  }

  created () {
    this.getStartLists()
    this.checkCompletedField()
  }
}
