
import "@/assets/styles/imports/promo.sass"
import { Component, Vue } from 'vue-property-decorator'
import storage from '@/storage'

@Component
export default class ThePromo extends Vue {
  get login () {
    return !!storage.user
  }
}
